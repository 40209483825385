import React, { useState } from "react"
import Layout from "../components/layout.js"
import Clients from "../components/clients/clients.js"
import MakeItReal from "../components/MakeItReal.js"
import OurProcess from "../components/OurProcess.js"
import AnimatedText from "../components/AnimatedText.js"
import StartAProjectBig from "../components/StartAProjectBig/StartAProjectBig.js"
import SEO from "../components/seo"
import ButtonRounded from "../components/ButtonRounded"

import Hero from "../components/Hero/Audit"
import HowItWorks from "../components/Hero/Audit/howItWorks"
import OurPrices from "../components/OurPrices"
import OurTools from "../components/OurTools"
import ProjectBanner from "../components/ProjectBanner"
import { ourTools } from "../content/homepage/ourToolsContent"
import { projectBanner } from "../content/homepage/projectBannerContent"
import styles from "./build.module.css"
import approachStyles from "../components/OurApproach/ourApproach.module.css"
import quote from "../images/quote.png"
import takeplace from "../images/work/takeplacelogo.png"
import macmillan from "../images/work/macmillan.png"
import life from "../images/work/life.png"
import ferly from "../images/work/ferly.png"
import ogilvy from "../images/work/ogilvy.png"

import classNames from "classnames/bind"
import simpleScroll from "simple-scroll"
import ideas from "../images/ideas.svg"
import build from "../images/build.svg"
import launch from "../images/launch.svg"
import innovate from "../images/innovate.svg"
import CornerContainer from "../components/CornerContainer/CornerContainer.js"
import { Link } from "gatsby"

// if (!isServer) {
// 	require('viewport-units-buggyfill').init();
// }

const UKFlag = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2702_1342)">
      <path
        d="M18.0908 0.90918H0.909002V18.091H18.0908V0.90918Z"
        fill="#012169"
      />
      <path
        d="M0.909002 0.90918V3.05691L7.28507 9.50009L0.909002 15.7755V18.091H3.1574L9.56703 11.782L15.8089 18.091H18.0908V15.809L11.849 9.53365L18.0908 3.39249V0.90918H16.0102L9.56703 7.21813L3.3252 0.90918H0.909002Z"
        fill="white"
      />
      <path
        d="M11.9161 11.782L11.547 12.923L16.6814 18.091H18.0908V17.9903L11.9161 11.782ZM7.75488 11.3793L5.94274 11.6478L0.909002 16.5809V18.091L7.75488 11.3793ZM0.909002 0.90918L7.35218 7.48659L7.48642 6.01003L2.45268 0.90918H0.909002ZM18.0908 0.942738L11.6141 7.28524L13.594 7.01678L18.0908 2.55353V0.942738Z"
        fill="#C8102E"
      />
      <path
        d="M12.1846 0.90918V18.091H6.81525V0.90918H12.1846ZM18.0908 6.81543V12.1847H0.909002V6.81543H18.0908Z"
        fill="white"
      />
      <path
        d="M18.0908 7.88929V11.1109H0.909002V7.88929H18.0908ZM11.1107 0.90918V18.091H7.88912V0.90918H11.1107Z"
        fill="#C8102E"
      />
    </g>
    <defs>
      <clipPath id="clip0_2702_1342">
        <rect
          width="17.1818"
          height="17.1818"
          rx="8.59091"
          transform="matrix(-1 0 0 1 18.0908 0.90918)"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
)

const ProjectItem = ({ imgSrc, videoSrc, title, subtitle, link }) => {
  return (
    <a href={link || "#"} target="_blank" className={styles.projectItem}>
      <div className={styles.projectItemImgWrapper}>
        <div
          className={styles.projectItemImg}
          style={{ backgroundImage: `url(${imgSrc})` }}
        />
        {videoSrc && (
          <video
            src={videoSrc}
            className={styles.projectVideo}
            autoPlay
            playsInline
            loop
            muted
          />
        )}
      </div>

      <p className={styles.projectName}>
        <b>{title}</b> - {subtitle}
      </p>
    </a>
  )
}

const Index = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [animationComplete, setAnimationComplete] = useState(false)
  const [progress, setProgress] = useState(1)
  const [scrollOpacity, setScrollOpacity] = useState(1)

  const logo = () => {
    return (
      <h1 className={styles.logo}>
        <span className={styles.logoDetail}>.</span>
      </h1>
    )
  }

  return (
    <Layout noFade minimalHeader>
      <SEO
        title="Digital Product Agency | Rapid Product Development"
        description="Transform your vision into a scalable, robust and beautiful digital product. We are a  product agency with a focus on designing & developing  mobile, software & web apps."
        image="/images/undefined-audit-share.jpg"
      />

      {/* <Hero /> */}
      <div className={styles.hero}>
        <p className={styles.local}>
          <UKFlag />
          Top Rated Boutique UK Agency with London based team
        </p>
        <h1 className={styles.title}>
          We build and scale
          <br />
          <b>headless websites</b>
        </h1>
        <p className={styles.subtitle}>
          We build <b>highly crafted custom digital experiences</b> for
          companies who <b>care</b>. Undefined is a team of{" "}
          <b>designers & developers</b> who want to bring back a human touch to
          the web. Specialising in high performing Web Platforms that look
          great.
        </p>
        <ButtonRounded to="#contact" text="Let's talk" />
        {/* <a href="#">Let's talk</a> */}
        {/* <a href="#">Watch showreel</a> */}
      </div>

      <div className={styles.projectContainer}>
        <ProjectItem
          imgSrc={"/images/build/aether.png"}
          link="https://experienceaether.com/"
          title="Aether"
          videoSrc="/images/build/aether-video.mp4"
          subtitle="Crafting an effortless web experience for a private airport terminal "
        />
        <ProjectItem
          imgSrc={"/images/build/thrift.jpg"}
          title="Thrift"
          link="https://thrift.plus"
          videoSrc="/images/build/thrift.mp4"
          subtitle="Building a performant D2C platform for the seamless pre-loved marketplace"
        />
        <ProjectItem
          imgSrc={"/images/build/fairgame.png"}
          title="Fairgame"
          link="https://www.wearefairgame.com/"
          videoSrc="/images/build/fairgame.mp4"
          subtitle="Supporting an interactive fairground experience in London "
        />
        <ProjectItem
          imgSrc={"/images/build/miq.png"}
          title="MiQ"
          link="https://www.wearemiq.com"
          videoSrc="/images/build/miq.mp4"
          subtitle="Not your average web experience for a programmatic media partner"
        />
      </div>

      <div className={styles.spacer} />

      <CornerContainer>
        <div className={styles.quotes}>
          <div className={approachStyles.quoteContainer}>
            <img className={approachStyles.quoteMark} src={quote} alt="quote" />

            <figure className={approachStyles.figure}>
              <blockquote className={approachStyles.quote}>
                This is Undefined delivered on time and was a joy to work with
                through all the typical startup iterations
              </blockquote>
              <figcaption className={approachStyles.smallText}>
                Matt Roele, CTO @ Topia
              </figcaption>
            </figure>
          </div>
          <div className={approachStyles.clientLogosContainer}>
            <div className={approachStyles.smallText}>Also trusted by</div>
            <img className={approachStyles.logo} src={ogilvy} alt="ogilvy" />
            <img
              className={approachStyles.logo}
              src={takeplace}
              alt="takeplace"
            />
            <img
              className={approachStyles.logo}
              src={macmillan}
              alt="macmillan"
            />
            <img className={approachStyles.logo} src={ferly} alt="ferly" />
            <img className={approachStyles.logo} src={life} alt="life" />
          </div>
        </div>

        {/* Work With us */}
      </CornerContainer>

      {/* What we do */}

      <CornerContainer backgroundColor="colorLightGrey" overlapSpacing>
        <div className={styles.whatWrapper}>
          <div className={styles.whatWeDo}>
            <p className={styles.detailLine}>What we do</p>
            <h3 className={styles.detailTitle}>
              Platforms from zero to scale in no time - without the stress
            </h3>

            <h4>Idea Validation</h4>
            <p className={styles.whatDescription}>
              We help businesses turn the <b>right</b> ideas into reality with
              our secret sauce: Rapid Prototyping. Getting ideas in your teams
              hands as quickly as possible.
            </p>
            <h4>UX/UI</h4>
            <p className={styles.whatDescription}>
              When you put the user first, you're already ahead of the game.
              That’s why we make sure your interfaces are beautiful and{" "}
              <b>user-friendly</b>.
            </p>

            <h4>Development</h4>
            <p className={styles.whatDescription}>
              We develop <b>future-proof</b> web & mobile platforms that stand
              the test of time and make up the foundation of a solid product
              that can keep innovating past it’s first itteration.
            </p>
          </div>

          <div className={styles.whatLookingFor} id="contact">
            <p className={styles.detailLine}>What are you looking for?</p>
            <h3 className={styles.detailTitle}>Got Ideas? Let’s team up!</h3>
            <form
              name="product-development"
              method="post"
              data-netlify="true"
              className={styles.contactForm}
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="bot-field" />
              <input
                type="hidden"
                name="form-name"
                value="product-development"
              />
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Your Name"
                required
              />

              <input
                type="email"
                name="email"
                placeholder="you@company.com"
                required
              />
              <textarea
                className={styles.lastInput}
                type="text"
                name="explanation"
                id="explanation"
                required
                placeholder="Tell us a little about your project"
                rows="10"
              />
              <button className={styles.formBtn} type="submit">
                Get in touch
              </button>
              <p className={styles.ourTeam}>
                <img src="/images/build/siebe.png" />
                Our team will respond within 24 hours
              </p>
            </form>
          </div>
        </div>
        <div className={styles.spacer} />
      </CornerContainer>

      <OurTools content={ourTools} />

      {/* <HowItWorks /> */}
      {/* <OurPrices /> */}
      {/* <OurTools content={ourTools} /> */}
      {/* <StartAProjectBig /> */}
      {/* <ProjectBanner content={projectBanner} /> */}
    </Layout>
  )
}

export default Index
